/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { GTR, COLOR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import DecorativeBackground from 'ui/common/DecorativeBackground'
import DropdownButton from 'ui/common/DropdownButton'

import Lockup from '../Lockup'
import NavigationLink from '../NavigationLink'
import SaveAndContinueLaterButton from '../SaveAndContinueLaterButton'
import { TREATMENTS } from '@/common/lib/split/experiments/ExptLinearWillsFlow/constants'

const LOCKUP_PADDING = '14px'

/**
 * [1] Match line-height specified in typography.styl.
 *     This can be removed once we've migrated everything away from Stylus, but
 *     it's required for now as the stylus styles are not loaded for the server
 *     render of InitialLayout.
 *
 *     Without this there is a small layout shift as the base line-height
 *     specified in @farewill/ui's reset stylesheet is `1.5`.
 *
 * [2] Temporary workaround for us not being able to easily override the
 *     body background color globally. If we roll out the visual changes
 *     introduced by ExptLinearWillsFlow to everyone, we can remove this and
 *     set the global default background to white in common/ui/layouts/InitialLayout/component.js.
 */
const StyledNavigationHeader = styled.header`
  ${
    ({
      $exptLinearWillsFlowTreatment,
      hideBorder,
      useDecorativeBackground,
    }) => `
    background-color: ${
      (hideBorder && $exptLinearWillsFlowTreatment !== TREATMENTS.Experiment) || // [2]
      useDecorativeBackground
        ? 'transparent'
        : COLOR.WHITE
    };
    color: ${COLOR.BLACK};
    border-bottom: solid 1px ${
      hideBorder || useDecorativeBackground ? 'transparent' : COLOR.GREY.LIGHT
    };
    padding-top: var(--main-navigation-header-top-padding, 0);
    position: relative;
    line-height: 1.5; ` // [1]
  };
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`

const StyledMenuButton = styled(DropdownButton)`
  line-height: 1;
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.BOLD};
  padding-top: var(--main-navigation-mobile-menu-button-top-padding, 0);
  position: relative;

  // increase touch area
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-1 * ${GTR.XS});
    right: calc(-1 * ${GTR.XS});
  }

  ${screenMin.l`
    display: none;
  `};
`

const StyledAccountMenuButton = styled(DropdownButton)`
  align-items: end;
  display: none;
  font-weight: ${FONT.WEIGHT.REGULAR};
  min-width: max-content;
  height: 100%;

  ${screenMin.l`
    display: flex;
    padding-bottom: var(--main-navigation-bottom-padding, ${GTR.S});
  `};
`

const StyledLockup = styled(Lockup)`
  padding: ${LOCKUP_PADDING} 0;
  width: auto;
`

const StyledNavigation = styled.nav`
  flex: 1;
  margin-left: var(--main-navigation-lockup-left-margin, ${GTR.S});
  display: none;

  ${screenMin.l`
    display: initial;
  `};

  ${screenMin.xl`
    margin-left: var(--main-navigation-lockup-left-margin-from-xl, ${GTR.M});
  `}
`

const StyledNavigationList = styled.ul`
  display: flex;
  margin: 0;
  height: 100%;
`

const StyledNavigationItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: ${GTR.M};

    ${screenMin.xl`
      margin-right: ${GTR.L};
    `}
  }

  height: 100%;
  line-height: 1;
`

const StyledNavigationLink = styled(NavigationLink)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  height: 100%;
  min-width: max-content;
  padding-bottom: var(--main-navigation-bottom-padding, ${GTR.S});
`

const StyledSpacer = styled.div`
  flex: 1;
`

const StyledDecorativeBackground = styled(DecorativeBackground)`
  z-index: -1;
`

const NavigationHeader = (
  {
    branding,
    exptLinearWillsFlowTreatment,
    className,
    isInGuidedFlow,
    isMobileMenuOpen,
    isAccountMenuOpen,
    onMobileMenuButtonClick,
    onAccountMenuButtonClick,
    loggedIn,
    navigationLinks,
    hideNavigation,
    hideBorder,
    useDecorativeBackground,
  },
  ref
) => {
  console.debug(`[NavigationHeader]`, {
    exptLinearWillsFlowTreatment,
    hideBorder,
    useDecorativeBackground,
  })
  return (
    <StyledNavigationHeader
      ref={ref}
      $exptLinearWillsFlowTreatment={exptLinearWillsFlowTreatment}
      className={className}
      hideBorder={hideBorder || useDecorativeBackground}
      useDecorativeBackground={useDecorativeBackground}
    >
      {useDecorativeBackground && <StyledDecorativeBackground />}
      <StyledWrapper
        container
        className="navigation-container"
        containerPaddingBottom={0}
        containerPaddingTop={0}
      >
        <StyledLockup branding={branding} hideNavigation={hideNavigation} />

        {loggedIn && !hideNavigation && (
          <>
            <StyledMenuButton
              isOpen={isMobileMenuOpen}
              onClick={onMobileMenuButtonClick}
            >
              Menu
            </StyledMenuButton>

            <StyledNavigation aria-labelledby="navigation">
              <StyledNavigationList>
                {navigationLinks.map((link, i) => (
                  <StyledNavigationItem key={i}>
                    <StyledNavigationLink to={link.url}>
                      {link.name}
                    </StyledNavigationLink>
                  </StyledNavigationItem>
                ))}
                <StyledSpacer />
                <StyledNavigationItem>
                  <StyledAccountMenuButton
                    dataCy="account-menu-button"
                    isOpen={isAccountMenuOpen}
                    onClick={onAccountMenuButtonClick}
                  >
                    Your account
                  </StyledAccountMenuButton>
                </StyledNavigationItem>
              </StyledNavigationList>
            </StyledNavigation>
          </>
        )}

        {isInGuidedFlow && <SaveAndContinueLaterButton />}
      </StyledWrapper>
    </StyledNavigationHeader>
  )
}

NavigationHeader.propTypes = {
  branding: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  exptLinearWillsFlowTreatment: PropTypes.string,
  isMobileMenuOpen: PropTypes.bool.isRequired,
  isInGuidedFlow: PropTypes.bool.isRequired,
  onMobileMenuButtonClick: PropTypes.func.isRequired,
  isAccountMenuOpen: PropTypes.bool.isRequired,
  onAccountMenuButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  loggedIn: PropTypes.bool,
  navigationLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  hideNavigation: PropTypes.bool,
  hideBorder: PropTypes.bool,
  useDecorativeBackground: PropTypes.bool,
}

NavigationHeader.defaultProps = {
  className: null,
  loggedIn: false,
  hideNavigation: false,
  hideBorder: false,
}

export default React.forwardRef(NavigationHeader)
