import * as Sentry from '@sentry/browser'
import axios from 'axios'
import get from 'lodash/get'

import {
  requestPending,
  requestCompleted,
  requestError,
} from '@/common/redux/requestsPending/actionCreators'
import { closeModal } from '@/common/redux/modal/actionCreators'
import { resetLogin } from '@/common/redux/login/actionCreators'
import { showAlert } from '@/common/redux/alert/actionCreators'
import history from 'startup/common/history'
import { removeCookie } from 'lib/storage/cookies/helpers'

import { ClientError } from './helpers'

// Redux actions

export const changeStoreState = function (update) {
  return {
    type: 'CHANGE_STORE_STATE',
    update,
  }
}

export const setStoreState = function (update) {
  return {
    type: 'SET_STORE_STATE',
    update,
  }
}

export const unset = function (keys) {
  return {
    type: 'UNSET',
    keys,
  }
}

const unsetObject = function (keys) {
  return {
    type: 'UNSET_OBJECT',
    keys,
  }
}

export const tracking = function (trackingType, data) {
  return {
    type: 'TRACKING',
    trackingType,
    data,
  }
}

export const handleAPIErrors = (err) => {
  if (axios.isCancel(err)) {
    throw new ClientError('request_cancelled', 'Request was cancelled', {
      cancelled: true,
    })
  }

  if (!err.response) {
    throw err
  }

  throw new ClientError(
    err.response.statusText,
    err.response.data.reason,
    err.response.data.data,
    err.response.data.id
  )
}

const fetchOption = (
  path,
  { csrfToken, userId, method },
  data = {},
  cancelToken
) => {
  const headers = { contentType: 'application/json', 'csrf-token': csrfToken }
  if (userId) headers['user-id'] = userId

  return {
    method: method || 'POST',
    url: `/api/proxy${path}`,
    headers,
    data,
    cancelToken,
  }
}

export const loadDataPromise = () => (dispatch, getState) => {
  const requestUrl = '/accounts/load-data'
  dispatch(requestPending(requestUrl))
  const csrfToken = getState().config.csrfToken

  return axios(fetchOption(requestUrl, { csrfToken }))
    .then(({ data }) => {
      dispatch(setStoreState(data))
      dispatch(requestCompleted(requestUrl))
    })
    .catch((err) => {
      dispatch(requestError(requestUrl, err))
      dispatch(showAlert('error', err))
    })
}

export const logoutPromise = (url) => (dispatch, getState) => {
  const requestUrl = '/accounts/logout'
  dispatch(requestPending(requestUrl))
  const config = getState().config
  const csrfToken = config.csrfToken

  return axios(fetchOption(requestUrl, { csrfToken }))
    .then(() => dispatch(closeModal()))
    .then(() => removeCookie(config.SESSION_NAME))
    .then(() => Sentry.configureScope((scope) => scope.setUser({})))
    .then(() => dispatch(loadDataPromise()))
    .then(() => history.push(url || '/'))
    .then(() => dispatch(requestCompleted(requestUrl)))
    .catch((err) => {
      dispatch(requestError(requestUrl, err))
      dispatch(showAlert('error', err))
    })
}

export const methodCallPromise =
  (methodName, data, cancelToken) => (dispatch, getState) => {
    dispatch(requestPending(methodName))
    const csrfToken = getState().config.csrfToken
    const userId = getState().user._id
    const headers = { csrfToken, userId }

    return axios(
      fetchOption('/methods/' + methodName, headers, data, cancelToken)
    )
      .then(({ data }) => {
        dispatch(requestCompleted(methodName))
        return data
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          dispatch(requestCompleted(methodName))
        } else {
          dispatch(requestError(methodName, err))
        }

        if (get(err, ['response', 'status']) === 401) {
          return dispatch(logoutPromise('/log-in'))
        } else {
          return handleAPIErrors(err)
        }
      })
  }

export const requestResetPasswordPromise = (args) => (dispatch, getState) => {
  const requestUrl = '/accounts/request-reset-password'
  dispatch(requestPending(requestUrl))
  const csrfToken = getState().config.csrfToken

  return axios(fetchOption(requestUrl, { csrfToken }, args))
    .catch(handleAPIErrors)
    .finally(() => dispatch(requestCompleted(requestUrl)))
}

export const resetPasswordPromise =
  ({ token, password }) =>
  (dispatch, getState) => {
    const requestUrl = '/accounts/reset-password'
    dispatch(requestPending(requestUrl))
    const csrfToken = getState().config.csrfToken

    return axios(fetchOption(requestUrl, { csrfToken }, { token, password }))
      .then(() =>
        dispatch(showAlert('success', 'Your password has been reset.'))
      )
      .then(() => history.push('/log-in'))
      .catch(handleAPIErrors)
      .finally(() => {
        dispatch(resetLogin())
        dispatch(requestCompleted(requestUrl))
      })
  }

export const loginPromise =
  ({ email, password }) =>
  (dispatch, getState) => {
    const requestUrl = '/accounts/login'
    dispatch(requestPending(requestUrl))
    const csrfToken = getState().config.csrfToken

    return axios(
      fetchOption(requestUrl, { csrfToken }, { username: email, password })
    )
      .catch(handleAPIErrors)
      .finally(() => dispatch(requestCompleted(requestUrl)))
  }

export const loginWithJwtToken = (token) => (dispatch, getState) => {
  if (!token) {
    const noTokenError = new Error('No token provided')
    dispatch(showAlert('error', noTokenError.message))
    return Promise.reject(noTokenError)
  }

  const REQUEST_URL = 'accounts/telephone-user-login'
  dispatch(requestPending(REQUEST_URL))

  const csrfToken = getState().config.csrfToken

  return axios
    .post(
      `/api/proxy/${REQUEST_URL}`,
      {},
      {
        headers: {
          'csrf-token': csrfToken,
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(({ data }) => dispatch(setStoreState(data.state)))
    .catch((error) => {
      dispatch(requestError(REQUEST_URL, error, 'Invalid token'))
      return handleAPIErrors(error)
    })
    .finally(() => dispatch(requestCompleted(REQUEST_URL)))
}

export const createAccountPromise = (accountData) => (dispatch, getState) => {
  const requestUrl = '/accounts/register'
  dispatch(requestPending(requestUrl))
  const csrfToken = getState().config.csrfToken

  return axios(fetchOption(requestUrl, { csrfToken }, accountData))
    .then(({ data }) => dispatch(setStoreState(data)))
    .then(() => dispatch(unsetObject(['join'])))
    .then(() => dispatch(loadDataPromise()))
    .catch(handleAPIErrors)
    .finally(() => dispatch(requestCompleted(requestUrl)))
}

/**
 * This function sends marketing consent data to Backstage.
 * Currently we are gonna send marketing consent to both MongoDB
 * and Backstage and keep them in sync, to eventually move
 * the marketing consent entirely to Backstage.
 */
export const updateMarketingConsentInBackstagePromise =
  (marketingConsentData) => (dispatch, getState) => {
    const requestUrl = '/accounts/marketing-consent'
    dispatch(requestPending(requestUrl))
    const csrfToken = getState().config.csrfToken

    return axios(
      fetchOption(
        requestUrl,
        { csrfToken, method: 'PATCH' },
        marketingConsentData
      )
    )
      .catch(handleAPIErrors)
      .finally(() => dispatch(requestCompleted(requestUrl)))
  }

export const createUserPromise = (accountData) => (dispatch, getState) => {
  const requestUrl = '/accounts/create-user'
  dispatch(requestPending(requestUrl))
  const csrfToken = getState().config.csrfToken

  return axios(fetchOption(requestUrl, { csrfToken }, accountData))
    .then(({ data }) => dispatch(setStoreState(data)))
    .then(() => dispatch(unsetObject(['join'])))
    .catch((err) => {
      dispatch(requestError(requestUrl, err))
      return handleAPIErrors(err)
    })
    .then(() => dispatch(requestCompleted(requestUrl)))
}

export const validateEmailPromise = (email) => (dispatch, getState) => {
  const requestUrl = '/accounts/validate-email'
  dispatch(requestPending(requestUrl))
  const csrfToken = getState().config.csrfToken

  return axios(fetchOption(requestUrl, { csrfToken }, { email }))
    .catch((err) => {
      return handleAPIErrors(err)
    })
    .finally(() => dispatch(requestCompleted(requestUrl)))
}

export const serverCall = (methodName, data, dispatch, getState) => {
  dispatch(requestPending(methodName))
  const csrfToken = getState().config.csrfToken
  const userId = getState().user._id
  const headers = { csrfToken, userId }

  return axios(fetchOption('/methods/' + methodName, headers, data))
    .then(({ data }) => {
      dispatch(requestCompleted(methodName))
      return data
    })
    .catch((error) => {
      const response = error.response
      dispatch(requestError(methodName, error))

      if (response) {
        if (response.status === 401) {
          dispatch(logoutPromise('/log-in'))
        }
        throw new ClientError(
          response.statusText,
          response.data.reason,
          response.data.data
        )
      } else {
        throw new ClientError('500', 'Error connecting to server', error)
      }
    })
}
