import { connect } from 'react-redux'
import { getUser } from '@/common/redux/user/selectors'

import App from './component'

const mapStateToProps = (state) => {
  return {
    userId: getUser(state)._id,
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
