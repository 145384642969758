import React from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { splitSdk } from '@splitsoftware/splitio-redux'

import Routes from 'startup/common/routes'
import { useSplitCookieYesConsent } from '../hooks/useSplitCookieYesConsent'
import SecondarySplitClientProvider from '@/common/lib/split/SecondarySplitClientContext'

const App = ({ history, store, userId }) => {
  useSplitCookieYesConsent(splitSdk.factory)

  return (
    <SecondarySplitClientProvider userId={userId}>
      <Router history={history}>
        <Routes store={store} />
      </Router>
    </SecondarySplitClientProvider>
  )
}

App.displayName = 'App'

App.propTypes = {
  history: PropTypes.object,
  store: PropTypes.object,
  userId: PropTypes.string,
}
export default App
