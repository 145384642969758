import { createStore, applyMiddleware, compose } from 'redux'
import { withExtraArgument } from 'redux-thunk'
import axios from 'axios'

import logger from '@/common/redux/middleware/logger'
import toastMessage from '@/common/redux/middleware/toastMessage'
import tracking from '@/common/redux/middleware/tracking'
import pageScroll from '@/common/redux/middleware/pageScroll'
import recordProgress from '@/common/redux/middleware/recordProgress'
import rootReducer from 'rootReducer'

export function configureStore(initialState) {
  const showReduxInspector =
    process.env.RELEASE_ENV !== 'production' &&
    process.env.BROWSER &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'

  const composeEnhancers = showReduxInspector
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

  let store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        withExtraArgument({ axios }),
        toastMessage,
        logger,
        tracking,
        recordProgress,
        pageScroll
      )
    )
  )

  if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
      module.hot.accept('../rootReducer', () =>
        store.replaceReducer(require('../rootReducer').default)
      )
    }
  }

  return store
}
