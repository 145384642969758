import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getClient } from './getClient'

export const SecondarySplitClientContext = createContext()

const INITIAL_STATE = {
  client: null,
  isReady: false,
  isReadyPromise: null,
  isTimedOut: false,
  isTimedOutPromise: null,
}

const SecondarySplitClientProvider = ({ children, userId }) => {
  const [clientState, setClientState] = useState(INITIAL_STATE)

  useEffect(() => {
    console.debug('[SecondarySplitClientProvider] mounted')

    return () => {
      console.debug('[SecondarySplitClientProvider] unmounted')
    }
  }, [])

  useEffect(() => {
    console.debug('[SecondarySplitClientProvider] userId changed: ', userId)

    if (!userId) {
      setClientState(INITIAL_STATE)
    }

    // If there is no userId, we don't want to call getClient - We do NOT want
    // fetch the default client.
    if (userId) {
      getClient(userId, { setClientState })
    }
  }, [userId])

  return (
    <SecondarySplitClientContext.Provider
      value={{
        client: clientState.client,
        isReady: clientState.isReady,
        isReadyPromise: clientState.isReadyPromise,
        isTimedOut: clientState.isTimedOut,
        isTimedOutPromise: clientState.isTimedOutPromise,
      }}
    >
      {children}
    </SecondarySplitClientContext.Provider>
  )
}

SecondarySplitClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userId: PropTypes.string,
}

export default SecondarySplitClientProvider
