import { methodCallPromise, serverCall } from 'startup/common/actionCreators'
import { hasPartnerStatus } from 'startup/common/helpers'
import { getWillById } from '@/common/redux/wills/selectors'
import { updateFuneralWishes } from '@/common/redux/funeralWishes/actionCreators'

export const changeUserEmail = (value) => ({
  type: 'CHANGE_USER_EMAIL',
  payload: value,
})

export const changeUserExecutors = (value) => ({
  type: 'CHANGE_USER_EXECUTORS',
  payload: value,
})

export const changeFarewillTrusteesIsAnExecutor = (value) => ({
  type: 'CHANGE_USER_FAREWILL_TRUSTEES_IS_AN_EXECUTOR',
  payload: value,
})

export const changeUserFullLegalName = (value) => ({
  type: 'CHANGE_USER_FULL_LEGAL_NAME',
  payload: value,
})

export const changeUserPreferredName = (value) => ({
  type: 'CHANGE_USER_PREFERRED_NAME',
  payload: value,
})

export const changeUserDOB = (value) => ({
  type: 'CHANGE_USER_DOB',
  payload: value,
})

export const changeUserAddressLine1 = (value) => ({
  type: 'CHANGE_USER_ADDRESS_LINE_1',
  payload: value,
})

export const changeUserAddressLine2 = (value) => ({
  type: 'CHANGE_USER_ADDRESS_LINE_2',
  payload: value,
})

export const changeUserCity = (value) => ({
  type: 'CHANGE_USER_CITY',
  payload: value,
})

export const changeUserPostCode = (value) => ({
  type: 'CHANGE_USER_POSTCODE',
  payload: value,
})

export const changeUserPhoneNumber = (value) => ({
  type: 'CHANGE_USER_PHONE_NUMBER',
  payload: value,
})

export const changeUserRelationship = (relationshipStatus, relationshipId) => {
  const inRelationship = hasPartnerStatus(relationshipStatus)

  if (!inRelationship) {
    relationshipId = null
  }

  return {
    type: 'CHANGE_USER_RELATIONSHIP',
    payload: {
      relationshipStatus,
      relationshipId,
      inRelationship,
    },
  }
}

// Consent to be contacted by the partner from whom the customer has redeemed a voucher
export const updateRedemptionPartnerPermissions =
  (
    redemptionPartnerMarketingPermissionsEmail,
    redemptionPartnerMarketingPermissionsPhone,
    redemptionPartnerMarketingPermissionsPost,
    willId,
    onSuccess
  ) =>
  async (dispatch, getState) => {
    const { issuesToConfirm, preDownloadCapacityCheckedAt } = getWillById(
      willId
    )(getState())

    const permissions = {
      redemptionPartnerMarketingPermissionsGiven:
        redemptionPartnerMarketingPermissionsEmail ||
        redemptionPartnerMarketingPermissionsPhone ||
        redemptionPartnerMarketingPermissionsPost,
      redemptionPartnerMarketingPermissionsEmail,
      redemptionPartnerMarketingPermissionsPhone,
      redemptionPartnerMarketingPermissionsPost,
    }

    await dispatch(methodCallPromise('users/update', permissions))

    dispatch({
      type: 'SET_REDEMPTION_PARTNER_MARKETING_PERMISSIONS',
      payload: permissions,
    })

    onSuccess({
      preDownloadCapacityCheckedAt,
      issuesToConfirm,
      willId,
    })
  }

export const asyncGetRedemptionPartner = () => async (dispatch) => {
  const redemptionPartner = await dispatch(
    methodCallPromise('users/get-redemption-partner')
  )

  dispatch({
    type: 'SET_REDEMPTION_PARTNER_DETAILS',
    payload: redemptionPartner,
  })

  return redemptionPartner
}

export const changeUserMarketingSource = ({
  marketingSource,
  marketingSourceSpecifics,
}) => ({
  type: 'CHANGE_USER_MARKETING',
  payload: {
    marketingSource,
    marketingSourceSpecifics,
  },
})

export const changeUserFuneralRestingPlace = (value) => ({
  type: 'CHANGE_USER_FUNERAL_RESTING_PLACE',
  payload: value,
})

export const changeUserFuneralRestingPlaceAdditionalWishes = (value) => ({
  type: 'CHANGE_USER_FUNERAL_RESTING_PLACE_ADDITIONAL_WISHES',
  payload: value,
})

export const resetFuneralRestingPlaceAdditionalWishes = () => ({
  type: 'RESET_USER_FUNERAL_RESTING_PLACE_ADDITIONAL_WISHES',
})

export const changeUserFuneralAdditionalWishes = (value) => ({
  type: 'CHANGE_USER_FUNERAL_ADDITIONAL_WISHES',
  payload: value,
})

export const changeUserFuneralSpecificWishes = (value) => ({
  type: 'CHANGE_USER_FUNERAL_SPECIFIC_WISHES',
  payload: value,
})

export const setUserPolicyAcceptance = ({
  termsAcceptedAt,
  privacyPolicyAcceptedAt,
}) => ({
  type: 'SET_USER_POLICY_ACCEPTANCE',
  payload: { termsAcceptedAt, privacyPolicyAcceptedAt },
})

export const userUpdateSuccess = (string) => ({
  type: 'USER_UPDATE_SUCCESS',
  message: string || 'Saved.',
})

export const userLoadError = (error) => ({
  type: 'USER_LOADING_ERROR',
  error,
})

export const asyncUserUpdate = (data) => (dispatch, getState) => {
  return serverCall('users/update', data, dispatch, getState).then((result) => {
    for (let key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue
      }

      switch (key) {
        case 'fullLegalName':
          dispatch(changeUserFullLegalName(result[key]))
          break
        case 'preferredName':
          dispatch(changeUserPreferredName(result[key]))
          break
        case 'DOB':
          dispatch(changeUserDOB(result[key]))
          break
        case 'addressLine1':
          dispatch(changeUserAddressLine1(result[key]))
          break
        case 'addressLine2':
          dispatch(changeUserAddressLine2(result[key]))
          break
        case 'city':
          dispatch(changeUserCity(result[key]))
          break
        case 'postCode':
          dispatch(changeUserPostCode(result[key]))
          break
        case 'phoneNumber':
          dispatch(changeUserPhoneNumber(result[key]))
          break
        case 'executors':
          dispatch(changeUserExecutors(result[key]))
          break
        case 'farewillTrusteesIsAnExecutor':
          dispatch(changeFarewillTrusteesIsAnExecutor(result[key]))
          break
        case 'relationshipStatus':
          dispatch(changeUserRelationship(result[key], result.relationshipId))
          break
        case 'funeralRestingPlace':
          dispatch(changeUserFuneralRestingPlace(result[key]))
          break
        case 'funeralRestingPlaceAdditionalWishes':
          dispatch(changeUserFuneralRestingPlaceAdditionalWishes(result[key]))
          break
        case 'funeralAdditionalWishes':
          dispatch(changeUserFuneralAdditionalWishes(result[key]))
          break
        case 'funeralSpecificWishes':
          dispatch(changeUserFuneralSpecificWishes(result[key]))
          break
        case 'farewillCremation':
          dispatch(updateFuneralWishes({ farewillCremation: result[key] }))
          break
        default:
          break
      }
    }
  })
}

export const removeAutoAppliedVoucherCode = () => ({
  type: 'REMOVE_AUTO_APPLIED_VOUCHER_CODE',
})
