/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import aboutYourselfRouteHandler from 'ui/routes/about-yourself'
import accountRouteHandler from 'ui/routes/account'
import accountsAndPropertyRouteHandler from 'ui/routes/accounts-and-property'
import capacityCheckRouteHandler from 'ui/routes/capacity-check'
import confirmationRouteHandler from 'ui/routes/confirmation'
import estateRouteHandler from 'ui/routes/estate'
import executorsRouteHandler from 'ui/routes/executors'
import funeralRouteHandler from 'ui/routes/funeral'
import giftsRouteHandler from 'ui/routes/gifts'
import guardiansRouteHandler from 'ui/routes/guardians'
import inviteRouteHandler from 'ui/routes/invite'
import loginRouteHandler from 'ui/routes/login'
import loginJwtRouteHandler from 'ui/routes/login-jwt'
import logoutRouteHandler from 'ui/routes/logout'
import notFoundRouteHandler from 'ui/routes/not-found'
import paymentRouteHandler from 'ui/routes/payment'
import peopleRouteHandler from 'ui/routes/people'
import printAndSendRouteHandler from 'ui/routes/print-and-send'
import purchaseSubscriptionRouteHandler from 'ui/routes/subscription'
import requestResetPasswordRouteHandler from 'ui/routes/request-reset-password'
import requestResetPasswordHelpRouteHandler from 'ui/routes/request-reset-password-help'
import resetPasswordRouteHandler from 'ui/routes/reset-password'
import singleSignOnRouteHandler from 'ui/routes/single-sign-on'
import sectionCompletedRouteHandler from 'ui/routes/section-completed'
import startRouteHandler from 'ui/routes/start'
import summaryRouteHandler from 'ui/routes/summary'
import willsRouteHandler from 'ui/routes/wills'
import { setScrollPosition } from 'lib/window'
import { closeMobileNav } from 'redux/showMobileNav/actionCreators'
import { closeAccountMenu } from 'redux/showAccountMenu/actionCreators'

import history from 'startup/common/history'

const handleScrolling = (location) => {
  const { hash } = location

  if (!hash) {
    setScrollPosition(0)
    return
  }

  // setTimeout hack to wait for components to render as DOM
  setTimeout(() => {
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
    }
  }, 0)
}

const pageEnterHandler = (store, location) => {
  store.dispatch(closeMobileNav())
  store.dispatch(closeAccountMenu())

  // Scroll page to top on route change
  // except when there is a hash
  handleScrolling(location)
}

const Routes = ({ store }) => {
  useEffect(() => {
    pageEnterHandler(store, history.location)
    history.listen((location) => pageEnterHandler(store, location))
  }, [])

  return (
    <Switch>
      <Route
        exact
        component={loginRouteHandler({
          isPublic: true,
          noIndex: true,
          hideNavigation: true,
          hideFooter: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/"
      />
      <Route
        component={loginRouteHandler({
          isPublic: true,
          noIndex: true,
          hideNavigation: true,
          hideFooter: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/log-in"
      />
      <Route
        component={loginJwtRouteHandler({
          isPublic: true,
          noIndex: true,
          hideNavigation: true,
          hideFooter: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/log-in-jwt"
      />
      <Route
        component={logoutRouteHandler({
          isPublic: true,
          noIndex: true,
        })}
        path="/logout"
      />
      <Route
        component={aboutYourselfRouteHandler({
          routeInGuidedFlow: true,
        })}
        path="/about-yourself"
      />
      <Route component={confirmationRouteHandler()} path="/confirmation" />
      <Route
        exact
        component={guardiansRouteHandler({
          routeInGuidedFlow: true,
        })}
        path="/guardians"
      />
      <Route
        component={guardiansRouteHandler({
          routeInGuidedFlow: true,
        })}
        path="/guardians/:childId/:page"
      />
      <Route component={giftsRouteHandler()} path="/gifts" />
      <Route
        component={estateRouteHandler({
          routeInGuidedFlow: true,
        })}
        path="/estate"
      />
      <Route
        component={executorsRouteHandler({
          routeInGuidedFlow: true,
        })}
        path="/executors"
      />
      <Route
        component={accountsAndPropertyRouteHandler({
          routeInGuidedFlow: true,
        })}
        path="/accounts-and-property"
      />
      <Route component={funeralRouteHandler()} path="/funeral" />
      <Route
        component={sectionCompletedRouteHandler({
          hideFooter: true,
          routeInGuidedFlow: true,
          useDecorativeBackground: true,
        })}
        path="/section-completed"
      />
      <Route component={summaryRouteHandler()} path="/summary" />
      <Route component={peopleRouteHandler()} path="/people" />
      <Route component={accountRouteHandler()} path="/account" />
      <Route
        component={paymentRouteHandler({
          hideNavigation: true,
          useDecorativeBackground: true,
        })}
        path="/payment"
      />
      <Route component={willsRouteHandler()} path="/wills" />
      <Route component={printAndSendRouteHandler()} path="/print-and-send" />
      <Route component={inviteRouteHandler()} path="/invite" />
      <Route
        component={capacityCheckRouteHandler({
          hideNavigation: true,
          hideBorder: true,
          hideFooter: true,
          centerContentVertically: true,
        })}
        path="/capacity-check"
      />
      <Route
        component={purchaseSubscriptionRouteHandler()}
        path="/purchase-subscription"
      />
      <Route
        component={requestResetPasswordRouteHandler({
          isPublic: true,
          noIndex: true,
          hideFooter: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/request-reset-password"
      />
      <Route
        component={requestResetPasswordHelpRouteHandler({
          isPublic: true,
          noIndex: true,
          hideFooter: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/request-reset-password-help"
      />
      <Route
        component={resetPasswordRouteHandler({
          isPublic: true,
          noIndex: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/reset-password"
      />
      <Route
        component={singleSignOnRouteHandler({
          isPublic: true,
          hideNavigation: true,
          hideBorder: true,
        })}
        path="/sso"
      />
      <Route
        component={startRouteHandler({
          isPublic: true,
          noIndex: true,
          hideFooter: true,
          hideNavigation: true,
          hideBorder: true,
          centerContentVertically: true,
        })}
        path="/start/:page"
      />
      <Route
        component={notFoundRouteHandler({
          isPublic: true,
          hideBorder: true,
        })}
        path="*"
      />
    </Switch>
  )
}

export default Routes
