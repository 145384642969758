import { createSelector } from 'reselect'

export const getProgresses = (state) => state.progresses

export const hasCompletedGuidedFlow = createSelector(
  getProgresses,
  (progresses) =>
    progresses?.some(
      (progress) =>
        progress.type === 'congratulations' && !!progress.completedAt
    )
)
