import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import { useSecondarySplitClientContext } from '@/common/lib/split/useSecondarySplitClientContext'
import { HeadInit } from 'ui/common/helpers'
import ModalLayout from 'ui/common/ModalLayout'
import Header from 'ui/common/header'
import Alert from 'ui/common/Alert'
import DecorativeBackground from 'ui/common/DecorativeBackground'
import CookieBanner from 'ui/common/CookieBanner'
import Loader from 'ui/common/loader'
import Login from 'ui/routes/login/views/root'
import Footer from 'ui/common/Footer'
import { getQueryParameters } from 'ui/common/helpers'
import { LayoutConfigType } from 'types'
import { MAIN_CONTENT } from 'lib/zIndex'
import {
  FLAG_NAME,
  TREATMENTS,
} from '@/common/lib/split/experiments/ExptLinearWillsFlow/constants'

const StyledRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: ${MAIN_CONTENT};

  ${({ $isScrollDisabled }) =>
    $isScrollDisabled &&
    `
    height: 100vh;
    overflow: hidden;
    position: fixed;
  `}
`

const commonWrapperStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  ${({ $verticallyCentered }) =>
    `justify-content: ${$verticallyCentered ? 'center' : 'flex-start'};`}
`

const StyledOuterContentWrapper = styled.div`
  ${commonWrapperStyles}
  ${({ $background }) => `background-color: ${$background}`};
`

const StyledInnerContentWrapper = styled(Wrapper)`
  ${commonWrapperStyles}

  position: relative;
  z-index: 2;
`

const Content = ({ children, hasAccessToContent, showContent }) => {
  if (hasAccessToContent && showContent) {
    return children
  } else if (hasAccessToContent) {
    return <Loader visible />
  } else {
    return <Login />
  }
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
  hasAccessToContent: PropTypes.bool.isRequired,
  showContent: PropTypes.bool.isRequired,
}

const MainLayout = ({
  children,
  config,
  hasCompletedGuidedFlow,
  isCookieBannerFeatureEnabled,
  isLoggedIn,
  isLoggedInWillsUser,
  isProduction,
  isModalOpen,
  location,
  redirectToOnboardingFlow,
  rootUrl,
  showAlert,
  trackUser,
}) => {
  const hasAccessToContent = isLoggedInWillsUser || config.isPublic

  useEffect(() => {
    const { successAlert, errorAlert } = getQueryParameters(location)

    if (successAlert || errorAlert) {
      showAlert({ successAlert, errorAlert })
    }

    if (isLoggedInWillsUser) {
      trackUser()
    }

    const isLoggedInNonWillsUser = isLoggedIn && !isLoggedInWillsUser

    if (isLoggedInNonWillsUser && !config.isPublic) {
      redirectToOnboardingFlow()
    }
  }, [])

  const { client, isReady, isTimedOut } = useSecondarySplitClientContext()

  const loggedInContentReady = isLoggedInWillsUser && (isReady || isTimedOut)
  const treatment = client?.getTreatment(FLAG_NAME)

  const isInGuidedFlow =
    config.routeInGuidedFlow &&
    treatment === TREATMENTS.Experiment &&
    !hasCompletedGuidedFlow

  const background =
    config.backgroundColor ||
    (treatment === TREATMENTS.Experiment ? COLOR.WHITE : COLOR.BACKGROUND.FOG)

  const useDecorativeBackground =
    config.useDecorativeBackground && treatment === TREATMENTS.Experiment

  console.debug('[MainLayout]', {
    client,
    isInGuidedFlow,
    treatment,
    useDecorativeBackground,
  })

  return (
    <>
      <ModalLayout />
      <Alert />
      <StyledRoot
        $isScrollDisabled={isModalOpen}
        // TODO: Once we've upgraded to React 18 we can hopefully avoid the
        // need for this weird syntax required for us to be able to use the
        // inert attribute. See https://camchenry.com/blog/how-to-disable-ui-and-control-focus-with-the-inert-attribute
        inert={isModalOpen ? 'inert' : undefined}
      >
        <HeadInit
          config={config}
          isProduction={isProduction}
          rootUrl={rootUrl}
        />
        {!isCookieBannerFeatureEnabled && <CookieBanner />}
        <Header
          exptLinearWillsFlowTreatment={treatment}
          hideBorder={hasAccessToContent ? config.hideBorder : true}
          hideNavigation={config.hideNavigation || isInGuidedFlow}
          isInGuidedFlow={isInGuidedFlow}
          isPublic={config.isPublic}
          useDecorativeBackground={isInGuidedFlow || useDecorativeBackground}
        />

        <StyledOuterContentWrapper
          $background={background}
          $verticallyCentered={config.centerContentVertically}
        >
          {useDecorativeBackground && <DecorativeBackground />}
          <StyledInnerContentWrapper
            noTrim
            $verticallyCentered={config.centerContentVertically}
          >
            <Content
              hasAccessToContent={hasAccessToContent}
              showContent={isLoggedInWillsUser ? loggedInContentReady : true}
            >
              {children}
            </Content>
          </StyledInnerContentWrapper>
        </StyledOuterContentWrapper>
        {!config.hideFooter && <Footer />}
      </StyledRoot>
    </>
  )
}

MainLayout.propTypes = {
  hasCompletedGuidedFlow: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  isLoggedInWillsUser: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  config: PropTypes.shape(LayoutConfigType),
  rootUrl: PropTypes.string.isRequired,
  isProduction: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  showAlert: PropTypes.func.isRequired,
  trackUser: PropTypes.func.isRequired,
  redirectToOnboardingFlow: PropTypes.func.isRequired,
  isCookieBannerFeatureEnabled: PropTypes.bool.isRequired,
  userId: PropTypes.string,
}

MainLayout.defaultProps = {
  config: {},
}

export default MainLayout
