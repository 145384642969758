import { useContext } from 'react'

import { SecondarySplitClientContext } from './SecondarySplitClientContext'

export const useSecondarySplitClientContext = () => {
  const context = useContext(SecondarySplitClientContext)

  if (context === undefined) {
    throw new Error(
      'useSecondarySplitClientContext must be used within an SecondarySplitClientContextProvider'
    )
  }
  return context
}
