import { connect } from 'react-redux'

import { closeModal } from '@/common/redux/modal/actionCreators'

import ModalLayout from './component'

const mapStateToProps = (state) => {
  const {
    hide,
    keepOpen,
    overlayOnly,
    direction,
    component,
    ...componentProps
  } = state.modal

  return {
    hide,
    keepOpen,
    overlayOnly,
    direction,
    component,
    componentProps,
  }
}

const mapDispatchToProps = {
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLayout)
